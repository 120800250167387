<template>
  <div class="login-container">
    <div class="header">
      <img class="header-banner" src="@/assets/up-edu/login-banner.svg"/>
      <p class="for">For</p>
      <img class="title-img" src="@/assets/up-edu/login-title.png"/>
      <p class="pc">サインイン</p>
      <div class="login-form">
        <div class="form-group">
          <label class="pc">メールアドレス</label>
          <input type="email" v-model="email" autocomplete="off" placeholder="ユーザー名、またはEメール" />
        </div>
        <div class="form-group">
          <label class="pc">パスワード</label>
          <input type="password" v-model="password" autocomplete="off" placeholder="パスワード"/>
        </div>
        <div
          class="error">
          {{error}}
        </div>        
      </div>
      <div class="btns">
        <button @click="login" class="btn-primary">サインイン</button>
        <div class="hide">
          <span class="line"></span>
          <span class="text">または</span>
          <span class="line"></span>
        </div>
        <img 
          @click="googleLogin"
          class="login-google hide"
          src="@/assets/google-signin.svg"/>        
      </div>
      <div class="links">
        <a href="https://www.prontest-series.com/series/forgotPassword.html">パスワードを忘れましたか？</a>
        <a href="https://www.prontest-series.com/series/regist.html">アカウント登録はこちら</a>
      </div>      
    </div>
    <div class="series-logo">
      <img src="@/assets/up-edu/logo-series.svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: null,
      password: null,
      error: '',
    }
  },
  computed: {
    disableLogin() {
      return !(this.email && this.password)
    },
    isSp() {
      return window.innerWidth < 750
    },
  },
  methods: {
    async login() {
      await this.$api.post("/up-edu/auth/login", {
        username: this.email,
        password: this.password,
      }, {
        hideErrorDialog: true
      }).then(res => {
        console.log('url:' + res.data.startUpUrlPc)
        location.href = this.isSp ? res.data.startUpUrlSp : res.data.startUpUrlPc
      }).catch(error => {
        this.error = error.data.message
      })
    },
    async googleLogin() {
      const googleUser = await this.$gAuth.signIn()
      if (!googleUser) return false
      await this.$api.post("/up-edu/auth/googleLogin", {idToken: googleUser.getAuthResponse().id_token}).then(res => {
        this.saveLoginResult(res)
      }).catch(error => {
        this.error = error.data.message
      })
    },
  },
  watch: {
    email() {
      this.error = ''
    },
    password() {
      this.error = ''
    },
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  margin: auto;
}
.hide {
  display: none;
}
.header {
  text-align: center;
  .header-banner {
    width: 90%;
  }
  .title-img {
    width: 80%;
  }
  .error {
    color: red;
    font-size: 12px;
    text-align: left;
    font-weight: bold;
    width: 85%;
    margin: auto;
  }  
  p {
    font-size: 25px;
    font-weight: bold;
  }
  .login-form {
    text-align: left;
    width: 80%;
    margin: auto;
    label {
      display: block;
    }
    input {
      border: none;
      background-color: white;
      border-bottom: 1px solid #1A1A1A;
      padding: 5px 10px 5px 5px;
      width: 100%;
      &::placeholder {
        color: #D1D1D1;
      }
    }
    .form-group {
      margin-bottom: 15px;
    }
  }
  .btns {
    display: flex;
    margin-top: 20px;
    justify-content: space-evenly;
    .btn-primary {
      height: 42px;
      color: white;
      background: #009843;
      border: none;
      border-radius: 3px;
      font-size: 16px;
      font-weight: bold;
      padding: 5px 15px;
      cursor: pointer;
      &:hover {
        border: 1px solid #009843;
        background: white;
        color: #009843;
      }
      &:active {
        transform: translate(0,2px);
      }
    }
    .login-google {
      width: 220px;
      cursor: pointer;
      &:active {
        transform: translate(0,2px);
      }
    }
  }
  .links {
    margin-top: 20px;
    a {
      text-decoration: underline;
      padding-left: 15px;
      text-underline-offset: 5px;
      text-decoration-thickness: 1px;
      cursor: pointer;
    }
    a:active, a:visited {
      color: black;
    }
  }
}
@media (min-width: 751px) {
  .sp {
    display: none;
  }
  .login-container {
    width: 550px;
  }
  .series-logo {
    position: absolute;
    right: 10px;
    top: calc(100vh - 60px);
    img {
      width: 190px;
    }
  }
}
@media screen and (max-width: 750px) {
  .pc {
    display: none !important;
  }
  .header {
    .for {
      margin-top: -10px;
    }
    .login-form {
      text-align: center;
      padding-top: 30px;
      input {
        border: none;
        background-color: #EBEBEB;
        border-radius: 5px;
        padding: 10px;
        width: 82%;
        border-bottom: none;        
      }
    }
    .links {
      font-size: 12px;
      margin-bottom: 30px;
    }
  }
  .btns {
    flex-direction: column;
    padding: 10px 20px;
    align-items: center;
    .btn-primary {
      width: 80%;
      margin-bottom: 20px;
    }
  }
  .or {
    display: flex;
    position: relative;
    width: 80%;
    margin-bottom: 20px;
    .line {
      border-bottom: 2px solid #D8D8D8;
      width: 25%;
      margin-bottom: 20px;
      display: inline-flex;
      position: relative;
      top: 10px;
    }
    .text {
      width: 50%;
      text-align: center;
    }
  }
  .series-logo {
    margin-top: 20px;
  }
  .series-logo {
    img {
      width: 200px;
    }
  }
}
</style>